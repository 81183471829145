import React, { Component } from "react";
import styled from "styled-components";
import _ from "lodash";
import { history } from "../../../history";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import screenfull from "screenfull";
const PersonVideoWrapper = styled.div``;
const PersonVideo = styled.div`
  justify-content: center;
  display: flex;
`;

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  icon: {
    marginRight: theme.spacing.unit * 2
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6
  }
});

export default class CarImageList extends Component {

  constructor(props) {
    super(props);
    this._onClickFullscreen = this._onClickFullscreen.bind(this);
  }

  _onClickFullscreen = () => {
    screenfull.toggle(this.player);
  };

  componentWillMount() {
    const { store } = this.props;

    const currentUser = store.getCurrentUser();

    if (currentUser) {
      // user is logged in we need redirect him to other page.

      const token = store.loadTokenFromLocalStorage();
      const userID = _.get(token, "userId", "");
      if (!userID) {
        history.push("/login");
      }
      //history.push(`/watch/${userID}`);
    } else {
      history.push("/login");
    }
  }

  render() {
    const { store } = this.props;
    const cars = store.getCarImageList();
    return (
      <PersonVideoWrapper>
        <PersonVideo>
          <div>
            <Grid
              style={styles.cardGrid}
              container
              spacing={40}
            >
              {cars.map((movie, index) => {
                const moviePath =
                  `${window.location.origin}/car/` +
                  _.get(movie, "phone").toString() +
                  "/" +
                  _.get(movie, "name").toString();
                const Date = _.get(movie, "created").toString();

                return (
                  <Grid item key={index} sm={6} md={4} lg={6}>
                    <Card style={styles.card}>
                      <CardContent style={styles.cardContent}>
                        <img
                            alt={''}
                            ref={c => {
                              this.player = c;
                            }}   onClick={this._onClickFullscreen} width="100%" height="100%" src={moviePath} />
                        <Typography>
                          {_.get(movie,'name').toString().substr(12)}
                        </Typography>
                        <Typography>
                          {Date}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </PersonVideo>
      </PersonVideoWrapper>
    );
  }
}
