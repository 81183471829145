import React, {Component} from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import screenfull from "screenfull";
import './watch.css';

const PlayerWrapper = styled.div`
    position : relative;
`
const PlayerInner = styled.div`
    display : inline-block
    padding-bottom : 10px
`


export default class MoviePlayer extends Component {

    constructor(props) {
        super(props);
        this._onClickFullscreen = this._onClickFullscreen.bind(this);
    }
    _zoomEventHandle(event){
        const target = event.target;
        const style = target.style;
        const targetWidth = style.width;
        let scale =  parseInt(targetWidth.substr(0,3))/100;
        if(isNaN(scale)){
            scale = 1;
        }
        const scalechange = 0.05;
        if(event.deltaY < 0){
            // zoom in
            scale = scale + scalechange;
        } else {
            // zoom out
            scale = scale - scalechange;
        }
        if(scale < 1){
            scale = 1;
        }
        const offsetX = -(event.x * (scale -1));
        const offsetY = -(event.y * (scale -1));
        style.width = String(scale* 100) + "%";
        style.height = String(scale * 100) + "%";
        style.transform = `translate(${offsetX}px,${offsetY}px)`;
        console.log(style.transform);
        event.preventDefault();
    }

    _changeFull = (event) => {
        const target = event.target;
        const style = target.style;
        const reactPlayer = event.target.parentElement;
        console.log(reactPlayer.classList);
        if(reactPlayer.classList.contains('fullPlayer')){
            style.width = "100%";
            style.height = "100%";
            style.transform = 'translate(0px,0px)';
            reactPlayer.removeEventListener('mousewheel',this._zoomEventHandle);
            reactPlayer.classList.remove('fullPlayer');
        } else {
            reactPlayer.addEventListener('mousewheel',this._zoomEventHandle);
            reactPlayer.classList.add('fullPlayer');
        }
    }

    _onClickFullscreen = (event) => {
        const target = event.target;
        const parent = event.target.parentElement;
        screenfull.toggle(parent);
        const style = target.style;
        if (screenfull.enabled) {
            screenfull.on('change', () => {
                if(screenfull.isFullscreen){
                    target.addEventListener('mousewheel',this._zoomEventHandle);
                } else {
                    style.width = "100%";
                    style.height = "100%";
                    style.transform = `translate(0px,0px)`;
                    target.removeEventListener('mousewheel',this._zoomEventHandle);
                }
            });
        }
    };


    render() {
        const liveChannel = this.props.streamPath;
        const streamUrl = `${window.location.origin}/${liveChannel}`;
        return <PlayerWrapper>
            <PlayerInner ref={c => {
                this.player = c;
            }}>
                <ReactPlayer onClick={this._changeFull}
                             width={'100%'} height={'100%'} url={streamUrl} playing={false} controls={true}/>
            </PlayerInner>
        </PlayerWrapper>
    }
}
