import React, {Component} from 'react';
import styled from 'styled-components'
import {history} from "../../../history";
import Typography from '@material-ui/core/Typography';

const LogoutWrapper = styled.div`

`
const LogoutButton = styled.button`
    height:30px;
    border: 1px solid black;
    padding: 5px 10px;
    background: #FFF;
    align-items : center;
    margin :10px 10px 10px 10px ;
    font-size: 14px;
    font-weight: 600;
    
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
`

const LogoutForm = styled.form`
`


export default class Logout extends Component {
    constructor(props) {
        super(props);
        this._onSubmit = this._onSubmit.bind(this);
    }

    _onSubmit(event) {
        const {store} = this.props;
        store.signOut();
        history.push('/logout');
    }

    render() {
        return (
            <LogoutWrapper>
                <LogoutForm onSubmit={this._onSubmit}>
                    <LogoutButton><Typography variant="button" color="inherit" noWrap>
                        로그아웃
                    </Typography></LogoutButton>
                </LogoutForm>
            </LogoutWrapper>
        )
    }
}