import React, {Component} from "react";
import styled from "styled-components";
import _ from "lodash"
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {withStyles} from '@material-ui/core/styles';
import TableCell from "@material-ui/core/TableCell/TableCell";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import PlusIcon from '@material-ui/icons/AddLocation';
import PlaceIcon from '@material-ui/icons/Place';

const RegionListWrapper = styled.div``;

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 200,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },  textField: {
        marginLeft: theme.spacing.unit*1,
        marginRight: theme.spacing.unit*1,
        width: '100%',
    },
    listPaper: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
});

class RegionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header : [
                {name: "지역번호", prop: "region_number"},
                {name: "지역", prop: "city"},
                {name:"세부주소", prop:"region"}
            ],
            regionData : [],
            page: 0,
            rowsPerPage: 5,

            newDialogOpen : false,
            newRegionCode : '',
            newRegionCity : '',
            newRegionName : '',

            editRegion : null,
            editRegionCode : '',
            editRegionCity : '',
            editRegionName : '',

            deleteDialogOpen: false,
            deleteRegion : null
        };
        this._handleChange = this._handleChange.bind(this);
    }

    componentDidMount() {
        const {store} = this.props;
        this._loadAllRegion(store);
    }

    _handleChange = name => event =>{
        const value = event.target.value;
        this.setState({[name]: value});
    }

    _loadAllRegion(store){
        store.getRegionData((res) => {
            this._setRegionList(res);
        });
    }

    _setRegionList(regionData) {
        this.setState({
            regionData
        })
    }

    _editClickOpen = region => {
        this.setState({
            editDialogOpen: true,
            editRegion : region,
            editRegionCity : _.get(region,'city',''),
            editRegionName : _.get(region,'region',''),
            editRegionCode : _.get(region,'region_number','')
        });
    };

    _editClose = () => {
        this.setState({
            editRegion : null,
            editRegionCode : '',
            editRegionCity : '',
            editRegionName : '',
            editDialogOpen : false,
        });
    }

    _deleteClickOpen = region => {
        this.setState({
            deleteDialogOpen: true,
            deleteRegion: region
        });
    };

    _deleteClose = () => {
        this.setState({
            deleteDialogOpen: false,
            deleteRegion : null
        });
    };

    newDialogOpen = () =>{
        this.setState({
            newDialogOpen : true,
        })
    };

    _newClose = () => {
        this.setState({
            newDialogOpen : false,
            newRegionCode : '',
            newRegionCity : '',
            newRegionName : '',
        })
    };

    _newRegion = () =>{
        const {regionData} = this.state;
        const {store} = this.props;
        const {newRegionCode} = this.state;
        const {newRegionCity} = this.state;
        const {newRegionName} = this.state;
        let noticeResult = [];
        store.newRegion(newRegionCode,newRegionCity,newRegionName, (res) =>{
            const newRow = {'_id': res._id, 'city' : res.city, 'region' : res.region , 'region_number' : res.region_number};
            noticeResult.push(newRow);
            regionData.map( (row,index) =>{
                noticeResult.push(row);
                return row;
            });
            this.setState({regionData : noticeResult});
            this.props.setRegionList(noticeResult);
            this.props.setCityList(noticeResult);
        });
    };

    _upDateRegion = regionId => {
        const {store} = this.props;
        const {editRegionCity} = this.state;
        const {editRegionCode} = this.state;
        const {editRegionName} = this.state;
        const {regionData} = this.state;
        const editRegion = {
            _id : regionId,
            city : editRegionCity,
            region : editRegionName,
            region_number: editRegionCode
        };
        let editResult = [];
        regionData.map( (row,index) =>{
           if(_.get(row,'_id') === regionId){
               row['city'] = editRegionCity;
               row['region'] = editRegionName;
               row['region_number'] = editRegionCode;
           }
            editResult.push(row);
           return row;
        });
        this.setState({regionData : editResult});
        store.updateRegion(editRegion);
        this.props.setRegionList(editResult);
        this.props.setCityList(editResult);
    };

    deleteRegion = regionId =>{
        const {store} = this.props;
        const {regionData} = this.state;
        let regionResult = [];
        regionData.map( (row,index) => {
            if(_.get(row,'_id','') !== regionId){
                regionResult.push(row);
            }
            return row;
        });
        this.setState({regionData:regionResult});
        this.props.setRegionList(regionResult);
        this.props.setCityList(regionResult);
        store.removeRegion(regionId);
    };

    _handleChangePage = (event, page) => {
        this.setState({page});
    };

    _handleChangeRowsPerPage = event => {
        this.setState({page: 0, rowsPerPage: event.target.value});
    };

    render() {
        const {regionData} = this.state;
        const {header} = this.state;
        const {classes} = this.props;
        const {rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, regionData.length - page * rowsPerPage);
        const row = (
            regionRow,
            index,
            header
        ) => {
            return (
                <TableRow key={`tr-${index}`} selectable="false">
                    {header.map((headerRow, k) => (
                        <CustomTableCell key={`trc-${k}`}>
                            {regionRow[headerRow.prop]}
                        </CustomTableCell>
                    ))}
                    <CustomTableCell>
                        <EditIcon onClick={() => this._editClickOpen(regionRow)}/>
                    </CustomTableCell>
                    <CustomTableCell>
                        <TrashIcon onClick={() => this._deleteClickOpen(regionRow)}/>
                    </CustomTableCell>
                </TableRow>
            );
        };
        return (
            <RegionListWrapper>
                <div>
                    <Dialog
                        open={this.state.deleteDialogOpen}
                        onClose={this._deleteClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"해당 지역코드를 삭제 하시겠습니까?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                지역 : {<span>{_.get(this.state.deleteRegion, 'city', '')}<br/></span>}
                                세부주소 : {<span>{_.get(this.state.deleteRegion, 'region', '')}<br/></span>}
                                지역번호 : {<span>{_.get(this.state.deleteRegion, 'region_number', '')}<br/></span>}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this._deleteClose} color="primary">
                                취소
                            </Button>
                            <Button onClick={() => {
                                this.deleteRegion(_.get(this.state.deleteRegion,'_id',''));
                                this._deleteClose()
                            }} color="primary" autoFocus>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.newDialogOpen}
                        onClose={this._newClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"지역번호 등록"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <TextField
                                    id="title"
                                    label="지역구"
                                    className={classes.textField}
                                    value={this.state.newRegionCity}
                                    onChange={this._handleChange('newRegionCity')}
                                    margin="normal"
                                />
                                <TextField
                                    id="title"
                                    label="세부주소"
                                    className={classes.textField}
                                    value={this.state.newRegionName}
                                    onChange={this._handleChange('newRegionName')}
                                    margin="normal"
                                />
                                <TextField
                                    id="title"
                                    label="지역코드"
                                    className={classes.textField}
                                    value={this.state.newRegionCode}
                                    onChange={this._handleChange('newRegionCode')}
                                    margin="normal"
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this._newClose} color="primary">
                                취소
                            </Button>
                            <Button onClick={() => {
                                this._newRegion();
                                this._newClose();
                            }} color="primary" autoFocus>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.editDialogOpen}
                        onClose={this._editClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"지역코드 수정"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <TextField
                                    id="city"
                                    label="지역구"
                                    className={classes.textField}
                                    value={this.state.editRegionCity}
                                    onChange={this._handleChange('editRegionCity')}
                                    margin="normal"
                                />
                                <TextField
                                    id="content"
                                    label="세부주소"
                                    className={classes.textField}
                                    value={this.state.editRegionName}
                                    onChange={this._handleChange('editRegionName')}
                                    margin="normal"
                                />
                                <TextField
                                    id="content"
                                    label="지역코드"
                                    className={classes.textField}
                                    value={this.state.editRegionCode }
                                    onChange={this._handleChange('editRegionCode')}
                                    margin="normal"
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this._editClose} color="primary">
                                취소
                            </Button>
                            <Button onClick={() => {
                                this._upDateRegion(_.get(this.state.editRegion,'_id',''));
                                this._editClose();
                            }} color="primary" autoFocus>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Grid container justify="center" alignItems="center">
                <Avatar className={classes.avatar}>
                    <PlaceIcon/>
                </Avatar>
            </Grid>
                <Typography component="h1" variant="h5" gutterBottom align={"center"}>
                    지역 코드 리스트
                </Typography>
                <Grid container justify="left" alignItems="center">
                    <Avatar className={classes.avatar} onClick={() => this.newDialogOpen()}>
                        <PlusIcon />
                    </Avatar>
                </Grid>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {header.map((headerRow,index) => (
                                    <CustomTableCell key={`thc-${index}`}>{headerRow.name}</CustomTableCell>
                                ))}
                                <CustomTableCell> 수정 </CustomTableCell>
                                <CustomTableCell> 삭제 </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {regionData !== undefined && regionData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((regionData, index) => {
                                    return row(
                                        regionData,
                                        index,
                                        header,
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 49 * emptyRows}}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="페이지 당 행 수"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={regionData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this._handleChangePage}
                        onChangeRowsPerPage={this._handleChangeRowsPerPage}
                    />
                </Paper>
            </RegionListWrapper>
        );
    }
}

RegionList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RegionList);