import React,{Component} from 'react'
import styled from 'styled-components'
import _ from "lodash";
import {history} from "../../history";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {withStyles} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import Notification from '@material-ui/icons/NotificationImportant'
import PlusIcon from '@material-ui/icons/AddComment'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
const FCM = require('fcm-push');

//fcm console에서 획득하는 서버키
var serverKey="AAAAEA6TC-4:APA91bHutD9MS1Pj5KRftuqRP8u29lMTds2c64FMQE2sdeoRZRKNyw8i3JNQ5bJJ5DQVpQGMwMv9tNIRrHqz_nsFGtZNTm51N2UfB8GZyF0cq1tnH4oVKRpkre03swiS2lTJCUvgweCw";

function sendNotice(appToken, title, contents){
    var push_data = {
        to: appToken,
        priority: "high",
        restricted_package_name: "com.haeinhan.cow",
        data: {
            title: title,
            content: contents,
            clickAction: "NoticeActivity"
        }
    }
    var fcm = new FCM(serverKey);
    fcm.send(push_data, function(err, response){
        if (err) {
            console.log("Something has gone wrong!");
        } else {
            console.log("Successfully sent with response: ", response);
        }
    });
}



const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 200,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },  textField: {
        marginLeft: theme.spacing.unit*1,
        marginRight: theme.spacing.unit*1,
        width: '100%',
    },
    listPaper: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
});

const NoticeWrapper = styled.div`
`;

class Notice extends Component{
    constructor(props){
        super(props);
        this.state ={
            notices : [],
            header : [
                {name: "번호", prop: "number"},
                {name: "제목", prop: "title"},
                {name:"등록일", prop:"created"}
            ],
            page: 0,
            rowsPerPage: 5,
            deleteDialogOpen : false,
            deleteNotice : null,
            editDialogOpen : false,
            editNotice : null,
            editTitle : null,
            editContent : null,
            editNumber : null,
            newDialogOpen : false,
            newTitle : null,
            newContent : null,
            usersApptoken : [],
    };
        this._handleChange = this._handleChange.bind(this);
    }

    componentDidMount() {
        const {store} = this.props;
        this._loadCurrentUser(store);
        this._loadNotices(store);
        this._loadUserApptoken(store);
    }
    _loadUserApptoken(store){
        store.getAlluserApptoken( (res) =>{
            this._setUserApptoken(res);
        } )
    }

    _setUserApptoken(usersApptoken){
        this.setState({usersApptoken});
    }

    _loadNotices(store){
        store.getNoticeData( (res)=>{
            this._setNotices(res);
        } )
    }

    _setNotices(notices){
        let noticeResult = [];
        let rowData = {};
        notices.map( (row,index) => {
            rowData = {number : index+1, ...row};
            noticeResult.push(rowData);
            return row;
        });
        this.setState({notices : noticeResult});
    }

    _loadCurrentUser(store) {
        store.getCurrentUser((res) => {
            this._setCurrentUser(res);
        });
    }

    _setCurrentUser(res) {
        const userID = _.get(res, '_id', '');
        if (!userID) {
            history.push("/login");
        } else if (userID === "5bfb320b7bcbdd73d7351915") {
            history.push("/register");
        } else {
            history.push(`/notice`);
            }
    }

    newClickOpen = () =>{
        this.setState({
            newDialogOpen : true,
        })
    };

    newClose = () => {
        this.setState({
            newDialogOpen : false,
            newTitle : null,
            newContent : null,
        })
    };

    editClickOpen = notice => {
        this.setState({
            editDialogOpen: true,
            editNotice : notice,
            editTitle : _.get(notice,'title',''),
            editContent : _.get(notice,'content',''),
            editNumber : _.get(notice,'number','')
        });
    };

    editClose = () => {
        this.setState({
            editDialogOpen : false,
            editNotice : null,
            editTitle : null,
            editContent : null,
            editNumber : null,
        });
    };

    deleteClickOpen = notice => {
        this.setState({
            deleteDialogOpen: true,
            deleteNotice: notice
        });
    };

    deleteClose = () => {
        this.setState({
            deleteDialogOpen: false,
            deleteNotice : null
        });
    };

    newNotice = () =>{
        const {store} = this.props;
        const {newTitle} = this.state;
        const {newContent} = this.state;
        const {notices} = this.state;
        const {usersApptoken} = this.state;
        let noticeResult = [];
        let rowData = {};
        store.newNotice(newTitle,newContent, (res) =>{
            rowData = {number : 1, ...res};
            noticeResult.push(rowData);
            notices.map( (row,index) => {
                    row.number = row.number+1;
                    noticeResult.push(row);
                    return row;
                }
            );
            this.setState({notices : noticeResult});
        });

        usersApptoken.map ( (user, index) =>{
            sendNotice(user.appToken,newTitle,newContent);
            return user;
        });
    };

    deleteNotice = noticeId =>{
        const {store} = this.props;
        const {notices} = this.state;
        let noticeResult = [];
        notices.map( (row,index) => {
            if(_.get(row,'_id','') !== noticeId){
                noticeResult.push(row);
            }
            return row;
        });
        this.setState({notices:noticeResult});
        store.removeNotice(noticeId);
    };

    updateNotice = noticeId =>{
        const {store} = this.props;
        const title= this.state.editTitle;
        const content =this.state.editContent;
        const {notices} = this.state;
        const notice = {
            _id : noticeId,
            title :title,
            content : content
        };
        let noticeResult = [];
        notices.map( (row,index) => {
            if(_.get(row,'_id','') === noticeId){
                row['title'] = title;
                row['content'] = content;
            }
            noticeResult.push(row);
            return row;
        });
        this.setState({notices:noticeResult});
        store.updateNotice(notice);

    };

    _handleChange = name => event =>{
        const value = event.target.value;
        this.setState({[name]: value});
    }

    render(){
        const {notices} = this.state;
        const {header} = this.state;
        const {classes} = this.props;
        const {rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, notices.length - page * rowsPerPage);
        const row = (
            noticeRow,
            index,
            header
        ) => {
            return (
                <TableRow key={`tr-${index}`} selectable="false">
                    {header.map((headerRow, k) => (
                        <CustomTableCell key={`trc-${k}`}>
                            {noticeRow[headerRow.prop]}
                        </CustomTableCell>
                    ))}
                    <CustomTableCell>
                        <EditIcon onClick={() => this.editClickOpen(noticeRow)}/>
                    </CustomTableCell>
                    <CustomTableCell>
                        <TrashIcon onClick={() => this.deleteClickOpen(noticeRow)}/>
                    </CustomTableCell>
                </TableRow>
            );
        };

        return(
            <NoticeWrapper>
                <div>
                    <Dialog
                        open={this.state.deleteDialogOpen}
                        onClose={this.deleteClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"해당 공지사항을 삭제 하시겠습니까?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                번호 : {<span>{_.get(this.state.deleteNotice, 'number', '')}<br/></span>}
                                제목 : {<span>{_.get(this.state.deleteNotice, 'title', '')}<br/></span>}
                                내용 : {<span>{_.get(this.state.deleteNotice, 'content', '')}<br/></span>}
                                등록일 : {<span>{_.get(this.state.deleteNotice, 'created', '')}<br/></span>}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.deleteClose} color="primary">
                                취소
                            </Button>
                            <Button onClick={() => {
                                this.deleteNotice(_.get(this.state.deleteNotice,'_id',''));
                                this.deleteClose()
                            }} color="primary" autoFocus>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.editDialogOpen}
                        onClose={this.editClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"공지사항 수정"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                번호 : {<span>{_.get(this.state.editNotice, 'number', '')}<br/></span>}
                                <TextField
                                    id="title"
                                    label="제목"
                                    className={classes.textField}
                                    value={this.state.editTitle}
                                    onChange={this._handleChange('editTitle')}
                                    margin="normal"
                                />
                                <TextField
                                    id="content"
                                    label="내용"
                                    className={classes.textField}
                                    value={this.state.editContent}
                                    onChange={this._handleChange('editContent')}
                                    margin="normal"
                                    multiline
                                    rows={"4"}
                                />
                                등록일 : {<span>{_.get(this.state.editNotice, 'created', '')}<br/></span>}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.editClose} color="primary">
                                취소
                            </Button>
                            <Button onClick={() => {
                                this.updateNotice(_.get(this.state.editNotice,'_id',''));
                                this.editClose()
                            }} color="primary" autoFocus>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.newDialogOpen}
                        onClose={this.newClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"공지사항 등록"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <TextField
                                    id="title"
                                    label="제목"
                                    className={classes.textField}
                                    value={this.state.newTitle}
                                    onChange={this._handleChange('newTitle')}
                                    margin="normal"
                                />
                                <TextField
                                    id="content"
                                    label="내용"
                                    className={classes.textField}
                                    value={this.state.newContent}
                                    onChange={this._handleChange('newContent')}
                                    margin="normal"
                                    multiline
                                    rows={"8"}
                                />
                               </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.newClose} color="primary">
                                취소
                            </Button>
                            <Button onClick={() => {
                                this.newNotice();
                                this.newClose();
                            }} color="primary" autoFocus>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
        <Grid container justify="center" alignItems="center">
            <Avatar className={classes.avatar}>
                <Notification/>
            </Avatar>
        </Grid>
        <Typography component="h1" variant="h5" gutterBottom align={"center"}>
            축사 관리 서비스 공지사항
        </Typography>
        <Grid container justify="left" alignItems="center">
            <Avatar className={classes.avatar} onClick={() => this.newClickOpen()}>
                <PlusIcon />
            </Avatar>
        </Grid>
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {header.map((headerRow,index) => (
                            <CustomTableCell key={`thc-${index}`}>{headerRow.name}</CustomTableCell>
                        ))}
                        <CustomTableCell> 수정 </CustomTableCell>
                        <CustomTableCell> 삭제 </CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {notices !== undefined && notices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((noticeRow, index) => {
                            return row(
                                noticeRow,
                                index,
                                header,
                            );
                        })}
                    {emptyRows > 0 && (
                        <TableRow style={{height: 49 * emptyRows}}>
                            <TableCell colSpan={6}/>
                        </TableRow>
                    )}
                </TableBody>
                </Table>
            <TablePagination
                labelRowsPerPage="페이지 당 행 수"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={notices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onChangePage={this._handleChangePage}
                onChangeRowsPerPage={this._handleChangeRowsPerPage}
            />
        </Paper>
            </NoticeWrapper>
        );
    }
}

Notice.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Notice);