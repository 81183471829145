import React, {Component} from 'react'
import styled from 'styled-components'
import StreamPlayer from "./streamPlayer";
import _ from 'lodash';
import {history} from "../../history";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import './watch.css';

const LiveWrapper = styled.div`
`
const CameraCard = styled.div`
        width : ${props => (props.value)};
        min-width: 300px;
        margin: 30px auto;
        border-radius: 40px;
        cursor: pointer;
        background: transparent;
        padding-right: 10px;
        @media only screen and (max-width: 600px){
        width : 100%
        }
`;

const styles = theme => ({
    radioRoot: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        display: 'flex',
        flexDirection: 'row',
        margin: `${theme.spacing.unit}px 0`,
    },
});


class Watch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cameras: [],
            width: "",
            pageSize: 4,
            currentPage: 1,
            items: {},
            pager: {}
        };
        this._loadUserCamera = this._loadUserCamera.bind(this);
    }

    componentWillMount() {
        const {store} = this.props;
        this._loadCurrentUser(store);
        this._loadUserCamera(store);
    }

    _loadCurrentUser(store) {
        store.getCurrentUser((res) => {
            this._setCurrentUser(res);
        });
    }

    _setCurrentUser(userID) {
        if (!userID) {
            history.push('/login');
        } else {
            history.push(`/watch/${userID}`);
            this.timerID2 = setInterval(() => this.props.updateData(), 1000);
        }
    }

    _loadUserCamera(store) {
        store.getUserOnceCameras((res) => {
            this._setUserCamera(res);
        });
    }

    _setUserCamera(cameras) {
        this.setState({cameras});
        const cameraSize = cameras.length;
        if (cameraSize > 9) {
            this.setState({width: '25%'})
        } else if (cameraSize > 4) {
            this.setState({width: '33%'})
        } else {
            this.setState({width: '50%'})
        }
    }

    _handleChange = event => {
        this.setState({
            width: event.target.value
        })
    };


    componentWillUnmount() {
        clearInterval(this.timerID2);
    }

    render() {
        const {classes} = this.props;
        const {cameras} = this.state;
        return <LiveWrapper>
            <div className={classes.radioRoot}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend" style={{fontSize: '25px'}}>카메라 형태</FormLabel>
                    <RadioGroup
                        aria-label="Gender"
                        name="gender1"
                        className={classes.group}
                        value={this.state.width}
                        onChange={this._handleChange}
                    >
                        <FormControlLabel value="50%" control={<Radio/>}
                                          className={"formControlLabel"} label="2 * 2"/>
                        <FormControlLabel value="33%" control={<Radio/>} className={"formControlLabel"}
                                          label="3 * 3"/>
                        <FormControlLabel value="25%" control={<Radio/>} className={"formControlLabel"}
                                          label="4 * 4"/>
                    </RadioGroup>
                    <FormHelperText style={{fontSize: '20px'}}>출력되는 카메라의 형태를 고르세요.</FormHelperText>
                </FormControl>
            </div>
            <div className={"cameraWrapper"}>
                {cameras.length === 0 ? null : cameras.map((cam, index) => {
                    return (
                        <CameraCard value={this.state.width} className={"cameraCard"} key={index}>
                            <div className={"cameraContent"}>
                                <StreamPlayer key={index} streamPath={_.get(cam, '_id')}/>
                                <div className={"cardTitle"}>
                                    <p>{_.get(cam, 'name', '')}</p>
                                </div>
                            </div>
                        </CameraCard>
                    )
                })}
            </div>
        </LiveWrapper>
    }
}

Watch.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Watch);