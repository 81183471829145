import React, {Component} from "react";
import styled from "styled-components";
import {history} from "../../../history";
import _ from "lodash";

const LogoutWrapper = styled.div``;
const CommentWrapper = styled.div``;


export default class LogoutFinish extends Component {

    componentWillMount() {
        const {store} = this.props;
        this.timerID = setInterval(() => this._loadCurrentUser(store), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    logout() {
        const {store} = this.props;
        store.signOut(() => {
            history.push('/login');
        });
    }

    _loadCurrentUser(store) {
        store.getCurrentUser((res) => {
            this._setCurrentUser(res);
        });
    }

    _setCurrentUser(res) {
        const userID = _.get(res, '_id', '');
        if (userID) {
            this.logout();
        } else {
            const {updateUser} = this.props;
            updateUser(null);
            history.push("/login");
        }
    }

    render() {

        return (
            <LogoutWrapper>
                <CommentWrapper>정상적으로 로그아웃이 완료되었습니다.</CommentWrapper>
            </LogoutWrapper>
        );
    }
}
