import React from 'react';
import Typography from '@material-ui/core/Typography';
import {history} from "../../../history";
import HorizontalBar from './horizontalBar';
import styled from "styled-components";
import _ from 'lodash'
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from '@material-ui/core/Paper';
import InsertChartOutlined from '@material-ui/icons/InsertChartOutlined';
import Router from '@material-ui/icons/Router';
import Avatar from "@material-ui/core/Avatar";

const DashBoardWrapper = styled.div`
`


const PaperContent = styled.div`
     align-items: center;
     margin : auto;
`
const styles = theme => ({
    main: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 400 + theme.spacing.unit * 3 * 2,
            marginLeft: 'auto',
            marginRight: 'auto',
        }, [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
            width: 500 + theme.spacing.unit * 3 * 2,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
            width: 700 + theme.spacing.unit * 3 * 2,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.up(1200 + theme.spacing.unit * 3 * 2)]: {
            width: 900 + theme.spacing.unit * 3 * 2,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.up(1400 + theme.spacing.unit * 3 * 2)]: {
            width: 1100 + theme.spacing.unit * 3 * 2,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    }, paper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit * 2,
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    dashboardContent: {
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    }
});

class Dashboard extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        return (JSON.stringify(this.state.findSensor) !== JSON.stringify(nextState.findSensor) ||
            JSON.stringify(this.state.currentCameras) !== JSON.stringify(nextState.currentCameras)
        );
    }


    constructor(props) {
        super(props);
        this.state = {
            sensorId: '',
            feed: '',
            temp: '',
            pump: '',
            fan: '',
            analog: '',
            findSensor: {},
            currentCameras: [],
        };
    }

    updateData() {
        /*const {store} = this.props;
        const {currentUser} = this.props;
        if (_.get(this.state.currentCameras, 'size', '') === 0 ||
            this.state.currentCameras.length === 0) {
            let cameras = store.getUserCameras();
            this.setState({
                currentCameras: cameras
            });
            const camerasArray = this.state.currentCameras.toArray();
            for (let i = 0; i < _.get(this.state.currentCameras, 'size'); i = i + 1) {
                store.start(camerasArray[i]);
            }
        }
        const sensorId = _.get(currentUser, 'sensor', '');
        let findSensor = store.getSensorData({sensor: sensorId});
        const findSensor_data = _.get(findSensor, '0', '');
        if (JSON.stringify(this.state.findSensor) !== JSON.stringify(_.get(findSensor, '0', ''))) {
            let feed = _.get(findSensor_data, 'feed', '');
            let temp = _.get(findSensor_data, 'temp', '');
            let pump = _.get(findSensor_data, 'pump', '');
            let fan = _.get(findSensor_data, 'fan', '');
            let analog = _.get(findSensor_data, 'analog', '');

            this.setState({
                sensorId: sensorId,
                feed: feed,
                temp: temp,
                pump: pump,
                fan: fan,
                analog: analog,
                findSensor: findSensor_data
            })
        }*/
    }

    _loadCameras(store) {
        store.getUserCameras((res) => {
            this._setCameras(res);
        });
    }

    _setCameras(currentCameras) {
        this.setState({currentCameras});
    }

    componentWillMount() {
        const {store} = this.props;
        this._loadCurrentUser(store);
        this._loadCameras(store);
    }

    _loadCurrentUser(store) {
        store.getCurrentUser((res) => {
            this._setCurrentUser(res);
            this._setSensorId(res);
        });
    }

    _setCurrentUser(res) {
        const userID = _.get(res, '_id', '');
        if (!userID) {
            history.push("/login");
        } else if (userID === "5bfb320b7bcbdd73d7351915") {
            history.push("/register");
        } else {
            history.push(`/dashboard`);
            //this.timerID = setInterval(() => this.updateData(), 1000);
            this.timerID2 = setInterval(() => this.props.updateData(), 1000);
        }
    }

    _setSensorId(res) {
        const {store} = this.props;
        const sensorId = _.get(res, 'sensor', '');
        this.setState({sensorId});
        store.getSensorData({sensor: sensorId}, (result) => {
            this._setSensorData(result)
        });
    }

    _setSensorData(res) {
        let feed = _.get(res, 'feed', '');
        let temp = _.get(res, 'temp', '');
        let pump = _.get(res, 'pump', '');
        let fan = _.get(res, 'fan', '');
        let analog = _.get(res, 'analog', '');

        this.setState({
            feed: feed,
            temp: temp,
            pump: pump,
            fan: fan,
            analog: analog
        })
    }

    componentWillUnmount() {
        clearInterval(this.timerID2);
    }

    render() {
        const {classes} = this.props;
        return (
            <DashBoardWrapper>
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <PaperContent>
                            <Avatar className={classes.avatar}>
                                <InsertChartOutlined/>
                            </Avatar>
                        </PaperContent>
                        <PaperContent>
                            <Typography component={"h1"} variant="h5" noWrap>
                                카메라당 승가 발생 횟수 통계그래프
                            </Typography>
                        </PaperContent>
                        <HorizontalBar
                            store={this.props.store}
                            currentCameras={this.state.currentCameras}
                        />
                    </Paper>
                    <Paper className={classes.paper}>
                        <PaperContent>
                            <Avatar className={classes.avatar}>
                                <Router/>
                            </Avatar>
                        </PaperContent>
                        <PaperContent>
                            <Typography component={"h1"} variant="h4" noWrap>
                                센서 데이터
                            </Typography>
                        </PaperContent>
                        <Typography variant="h5" noWrap>
                            센서 아이디 : {this.state.sensorId}
                        </Typography>
                        <Typography variant="h5" noWrap>
                            온도 : {this.state.temp}
                        </Typography>
                        <Typography variant="h5" noWrap>
                            사료통 상태 : {this.state.feed}
                        </Typography>
                        <Typography variant="h5" noWrap>
                            펌프 상태 : {this.state.pump}
                        </Typography>
                        <Typography variant="h5" noWrap>
                            선풍기 상태 : {this.state.fan}
                        </Typography>
                        <Typography variant="h5" noWrap>
                            아날로그 값 : {this.state.analog}
                        </Typography>
                    </Paper>
                </main>
            </DashBoardWrapper>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
