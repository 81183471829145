import React, {Component} from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import Fullscreen from "react-full-screen";
import './watch.css';

const FULL_CN = "fullPlayer";
const REACT_VIDEO_CN = "reactPlayer";

const PlayerWrapper = styled.div`
    width : 100%;
    border-radius : 40px;
`;
const PlayerInner = styled.div`
    border-radius : 40px;
    `;

export default class StreamPlayer extends Component {

    constructor(props) {
        super(props);
        this._onClickFullscreen = this._onClickFullscreen.bind(this);
        this.state = {
            isFull: false,
        }
    }

    goFull = () => {
        this.setState({ isFull: true });
    }

    _zoomEventHandle(event){
        const target = event.target;
        const style = target.style;
        const targetWidth = style.width;
        let scale =  parseInt(targetWidth.substr(0,3))/100;
        if(isNaN(scale)){
            scale = 1;
        }
        const scalechange = 0.05;
        if(event.deltaY < 0){
            // zoom in
            scale = scale + scalechange;
        } else {
            // zoom out
            scale = scale - scalechange;
        }
        if(scale < 1){
            scale = 1;
        }
        const offsetX = -(event.x * (scale -1));
        const offsetY = -(event.y * (scale -1));
        style.width = String(scale* 100) + "%";
        style.height = String(scale * 100) + "%";
        style.transform = `translate(${offsetX}px,${offsetY}px)`;
        event.preventDefault();
    }
    _changeFull = (event) => {
        const target = event.target;
        const style = target.style;
        const reactPlayer = event.target.parentElement;
        if(reactPlayer.classList.contains('fullPlayer')){
            style.width = "100%";
            style.height = "100%";
            style.transform = 'translate(0px,0px)';
            reactPlayer.removeEventListener('mousewheel',this._zoomEventHandle);
            reactPlayer.classList.remove('fullPlayer');
            reactPlayer.classList.add('reactPlayer');
        } else {
            reactPlayer.addEventListener('mousewheel',this._zoomEventHandle);
            reactPlayer.classList.add('fullPlayer');
            reactPlayer.classList.remove('reactPlayer');
        }
    }

    _onClickFullscreen = (event) => {
        screenfull.toggle(this.player);
        const target = event.target;
        const style = target.style;
        const reactPlayer = event.target.parentElement;
        const playerClassList = reactPlayer.classList;
        /*if(playerClassList.contains(REACT_VIDEO_CN)){
            playerClassList.add(FULL_CN);
            reactPlayer.addEventListener('mousewheel',this._zoomEventHandle);
            playerClassList.remove(REACT_VIDEO_CN);
        }else {
            playerClassList.add(REACT_VIDEO_CN);
            playerClassList.remove(FULL_CN);
            style.width = "100%";
            style.height = "100%";
            style.transform = `translate(0px,0px)`;
            reactPlayer.removeEventListener('mousewheel',this._zoomEventHandle);
        }*/

        if (screenfull.enabled) {
            screenfull.on('change', () => {
                if(screenfull.isFullscreen){
                    playerClassList.add(FULL_CN);
                    reactPlayer.addEventListener('mousewheel',this._zoomEventHandle);
                    playerClassList.remove(REACT_VIDEO_CN);
                } else {
                    playerClassList.add(REACT_VIDEO_CN);
                    playerClassList.remove(FULL_CN);
                    style.width = "100%";
                    style.height = "100%";
                    style.transform = '';
                    reactPlayer.removeEventListener('mousewheel',this._zoomEventHandle);
                }
            });
        }
    };


    render() {
        const liveChannel = this.props.streamPath;
        //const streamUrl = `http://api.ssnetworks.kr:4567/live/${liveChannel}/cam.m3u8`;

        const streamUrl = `${window.location.origin}/live/${liveChannel}/cam.m3u8`;
        console.log('liveChannel =', liveChannel);
        console.log('streamUrl =', streamUrl);
        return (
            <PlayerWrapper
                ref={c => {
                    this.player = c;
                }}>
                <PlayerInner >
                    <Fullscreen
                        enabled={this.state.isFull}
                        onChange={isFull => this.setState({isFull})}
                    >
                    <ReactPlayer
                        className={"reactPlayer"}
                        onClick={this._changeFull}
                        width={'100%'}
                        height={'100%'}
                        url={streamUrl}
                        playing={true}
                        controls={true}
                        muted={true}
                    />
                    </Fullscreen>
                </PlayerInner>
            </PlayerWrapper>
        );
    }
}
