import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';
import _ from "lodash";

export default class Horizontal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            findCameras: [],
            currentCameras: [],
            displayName: 'graph',
            labels: [],
            data: []
        }
    }

    componentWillMount() {
        const {store} = this.props;
        this._loadCameras(store);
        this._loadUserCharData(store);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    _loadCameras(store) {
        store.getUserCameras((res) => {
            this._setCameras(res);
            this._setLabels(res);
        });
    }

    _loadUserCharData(store) {
        store.getUserChartData((res) => {
            this._setUserChartData(res);
        });
    }

    _setUserChartData(res) {
        let datas = [];
        for (let i = 0; i < res.length; i = i + 1) {
            const data = parseInt(_.get(res[i], 'name', '').toString().substring(27, 28)) - 1;
            if (datas.length < data) {
                datas.length = data;
            }
            if (datas[data] === undefined) {
                datas[data] = 0;
            }
            datas[data] = datas[data] + 1
        }
        this.setState({
            data: datas
        })
    }

    _setCameras(currentCameras) {
        this.setState({currentCameras});
    }

    _setLabels(res) {
        let labels = [];
        for (let i = 0; i < res.length; i = i + 1) {
            labels.push(_.get(res[i], 'name', ''));
        }
        this.setState({labels});
    }

    updateData() {
        const {store} = this.props;
        const {currentCameras} = this.props;
        if (_.get(this.state.currentCameras, 'size', 0) === 0 ||
            this.state.currentCameras.length === 0
        ) {
            let labels = [];
            this.setState({
                currentCameras: currentCameras
            });
            const camerasArray = this.state.currentCameras.toArray();
            for (let i = 0; i < _.get(this.state.currentCameras, 'size'); i = i + 1) {
                labels.push(_.get(camerasArray[i], 'name', ''));
            }
            this.setState({
                labels: labels
            });
        }

        let findCameras = store.getUserChartData();
        if (JSON.stringify(this.state.findCameras) !== JSON.stringify(findCameras)) {

            let datas =[];
            this.setState({
                findCameras: findCameras
            });
            const camerasArray = this.state.findCameras.toArray();
            for (let i = 0; i < camerasArray.length; i = i + 1) {
                const data = parseInt(_.get(camerasArray[i], 'name', '').toString().substring(27, 28)) - 1;
                if (datas.length < data) {
                    datas.length = data;
                }
                if (datas[data] === undefined) {
                    datas[data] = 0;
                }
                datas[data] = datas[data] + 1
            }
            this.setState({
                data: datas
            })
        }
    }

    render() {

        let data = {
            labels: this.state.labels,
            datasets: [
                {
                    label: '카메라당 승가 발생 횟수',
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: this.state.data
                },
            ],
            scales: {
                yAxes: [{
                    ticks: {
                        min: 0,
                    }
                }]
            }
        };
        return (
            <div>
                <Bar data={data} options={{
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: 0,
                            }
                        }]
                    }
                }}/>
            </div>
        );
    }
}