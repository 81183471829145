import React, {Component} from "react";
import styled from "styled-components";
import _ from "lodash"
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {withStyles} from '@material-ui/core/styles';
import TableCell from "@material-ui/core/TableCell/TableCell";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import TextField from "@material-ui/core/TextField";
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const UserListWrapper = styled.div``;

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 200,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

class UserList extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)
            || (this.props.editIdx !== nextProps.editIdx)
            || (this.state.page !== nextState.page)
            || (this.state.rowsPerPage !== nextState.rowsPerPage)
            || (this.state.userDialogOpen !== nextState.userDialogOpen)
            ;
    }

    constructor(props) {
        super(props);
        this.state = {
            userDialogOpen: false,
            page: 0,
            rowsPerPage: 5,
            editUser: ''
        };

    }

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({page: 0, rowsPerPage: event.target.value});
    };

    userHandleClickOpen = user => {
        this.setState({
            userDialogOpen: true,
            editUser: user
        });
    };

    userHandleClose = () => {
        this.setState({userDialogOpen: false});
    };


    render() {
        const {classes} = this.props;
        const {header} = this.props;
        const {startEditing} = this.props;
        const {editIdx} = this.props;
        const {handleChange} = this.props;
        const {handleRemove} = this.props;
        const {stopEditing} = this.props;
        const {data} = this.props;
        const {rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        const row = (
            x,
            i,
            header,
            startEditing,
            editIdx,
            handleChange,
            handleRemove,
            stopEditing
        ) => {
            let currentlyEditing = editIdx === i;
            return (
                <TableRow key={`tr-${i}`} selectable="false">
                    {header.map((y, k) => (
                        <CustomTableCell key={`trc-${k}`}>
                            {currentlyEditing ? (
                                <TextField
                                    name={y.prop}
                                    onChange={e => handleChange(e, y.prop, _.get(x, 'phone'))}
                                    value={x[y.prop]}
                                />
                            ) : (
                                x[y.prop]
                            )}
                        </CustomTableCell>
                    ))}
                    <CustomTableCell>
                        {currentlyEditing ? (
                            <CheckIcon onClick={() => stopEditing(x)}/>
                        ) : (
                            <EditIcon onClick={() => startEditing(i)}/>
                        )}
                    </CustomTableCell>
                    <CustomTableCell>
                        <TrashIcon onClick={() => this.userHandleClickOpen(x)}/>
                    </CustomTableCell>
                </TableRow>
            );
        };
        return (
            <UserListWrapper>
                <div>
                    <Dialog
                        open={this.state.userDialogOpen}
                        onClose={this.userHandleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"해당 유저를 삭제 하시겠습니까?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                유저 이름 : {<span>{_.get(this.state.editUser, 'name', '')}<br/></span>}
                                핸드폰 번호 : {<span>{_.get(this.state.editUser, 'phone', '')}<br/></span>}
                                지역번호 : {<span>{_.get(this.state.editUser, 'region', '')}<br/></span>}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.userHandleClose} color="primary">
                                취소
                            </Button>
                            <Button onClick={() => {
                                handleRemove(this.state.editUser);
                                this.userHandleClose()
                            }} color="primary" autoFocus>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {header.map((x, i) => (
                                    <CustomTableCell key={`thc-${i}`}>{x.name}</CustomTableCell>
                                ))}
                                <CustomTableCell> 수정 </CustomTableCell>
                                <CustomTableCell> 삭제 </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data !== undefined && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((user, index) => {
                                    return row(
                                        user,
                                        index,
                                        header,
                                        startEditing,
                                        editIdx,
                                        handleChange,
                                        handleRemove,
                                        stopEditing
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 49 * emptyRows}}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="페이지 당 행 수"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            </UserListWrapper>
        );
    }
}

UserList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
