import React, {Component} from "react";
import styled from "styled-components";
import MoviePlayer from "../moviePlayer";
import _ from "lodash";
import {history} from "../../../history";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import './userMoviesList.css';

const UserVideoWrapper = styled.div``;

const MovieWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    z-index: 0;
    background: transparent;
`;

const MovieCard = styled.div`
        width : 45%;
        min-width: 300px;
        margin: 30px auto;
        border-radius: 15px;
        background: #f9f8fd;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        box-sizing: border-box;
        @media only screen and (max-width: 900px){
        width : 95%
        }
`;

export default class UserMovieList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            movies: [],
            items: [],
            pager: {},
            printPage: 10,
        };
        this._updateWindowDimensions = this._updateWindowDimensions.bind(this);
    }

    componentWillMount() {
        const {store} = this.props;
        this._updateWindowDimensions();
        window.addEventListener('resize', this._updateWindowDimensions);
        this._loadCurrentUser(store);
        this._loadUserMovies(store);
    }

    _updateWindowDimensions() {
        const {pager} = this.state;
        if (window.innerWidth > 850) {
            this.setState({printPage: 10});
        } else if (window.innerWidth > 750) {
            this.setState({printPage: 8});
        } else {
            this.setState({printPage: 6});
        }
        if (_.isEmpty(pager)) {
            return;
        }
        this._setPage(pager.currentPage);
    }

    _loadCurrentUser(store) {
        store.getCurrentUser((res) => {
            this._setCurrentUser(res);
        });
    }

    _setCurrentUser(res) {
        const userID = _.get(res, '_id', '');
        if (!userID) {
            history.push("/login");
        } else {
            this.timerID2 = setInterval(() => this.props.updateData(), 1000);
        }
    }

    _loadUserMovies(store) {
        store.getUserMovieList((res) => {
            this.setState({movies: res}, () => this._setPage(1));
        });
    }


    _setPage(page) {

        let {pager} = this.state;
        const movieSize = this.state.movies.length;
        if (_.isEmpty(pager)) {
            pager = this._getPager(movieSize, 1, 4);
            const items = this.state.movies.slice(pager.startIndex, pager.endIndex + 1);
            this.setState({items, pager});
            return;
        }
        if (page < 1 || page > pager.totalPages) {
            return;
        }
        pager = this._getPager(movieSize, page, 4);
        const items = this.state.movies.slice(pager.startIndex, pager.endIndex + 1);
        this.setState({items, pager});
    }

    _getPager(totalItems, currentPage, pageSize) {
        const totalPages = Math.ceil(totalItems / pageSize);
        const {printPage} = this.state;
        let startPage, endPage;
        if (totalPages <= printPage) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= (printPage / 2 + 1)) {
                startPage = 1;
                endPage = printPage;
            } else if (currentPage + printPage / 2 - 1 >= totalPages) {
                startPage = totalPages - printPage + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - printPage / 2;
                endPage = currentPage + printPage / 2 - 1;
            }
        }
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        const pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    componentWillUnmount() {
        clearInterval(this.timerID2);
    }

    render() {
        const {items} = this.state;
        const {pager} = this.state;
        return (
            <UserVideoWrapper>
                <MovieWrapper className={"moviesWrapper"}>
                    {!items || items.length === 0 ? null : items.map((movie, index) => {
                        const moviePath =
                            "media/" +
                            _.get(movie, "phone").toString() +
                            "/" +
                            _.get(movie, "name").toString();
                        const Date = _.get(movie, "created").toString();
                        return (
                            <MovieCard className={"movieCard"}>
                                <div className={"cameraContent"}>
                                    <MoviePlayer
                                        key={_.get(movie, '_id', '')}
                                        streamPath={moviePath}
                                        createDate={Date}
                                    />
                                    <div className={"movieTitle"}>
                                        <p>
                                            {_.get(movie, "name")
                                                .toString()
                                                .substr(27, 1)}번 카메라
                                        </p>
                                    </div>
                                    <div className={"movieDate"}>
                                        <p>
                                            승가 시간 : {Date}
                                        </p>
                                    </div>
                                </div>
                            </MovieCard>
                        );
                    })}
                </MovieWrapper>
                <div className={"moviePage"}>
                    <ul className={"pagination"}>
                        <LeftIcon className={pager.currentPage === 1 ? 'disabled' : 'direction'}
                                  onClick={() => this._setPage(pager.currentPage - 1)}>
                        </LeftIcon>
                        {!pager.pages || pager.pages.length < 1 ? null : pager.pages.map((page, index) =>
                            <li key={index} className={pager.currentPage === page ? 'active' : ''}>
                                <button onClick={() => this._setPage(page)}>{page}</button>
                            </li>
                        )}
                        <RightIcon
                            onClick={() => this._setPage(pager.currentPage + 1)}
                            className={pager.currentPage === pager.totalPages ? 'disabled' : 'direction'}>
                        </RightIcon>
                    </ul>
                </div>
            </UserVideoWrapper>
        );
    }
}
