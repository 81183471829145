import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import CameraEnhance from '@material-ui/icons/CameraEnhance';
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";


const styles = theme => ({
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    }, paper: {
        width: 400,
        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
    },
});

class AddCamera extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cameraMessage: {
                type: 'success',
                msg: null,
            }, camera: {
                cameraName: "",
                cameraPhone: "",
                cameraIp: "",
                public: false,
            },
        };
        this._onCameraSubmit = this._onCameraSubmit.bind(this);
        this._onCameraTextFieldChange = this._onCameraTextFieldChange.bind(this);
    }

    _onCameraTextFieldChange(event) {
        let {camera} = this.state;
        const field = event.target.name;
        const value = event.target.value;
        camera[field] = value;
        this.setState({
            camera: camera
        });
    }

    _onCameraCancel = () => {
        this.setState({
            camera: {
                cameraName: "",
                cameraPhone: "",
                cameraIp: "",
                public: false,
            }
        });
    };

    _onCameraSubmit(event) {
        event.preventDefault();
        const {store} = this.props;
        const {camera} = this.state;
        const {cameraData} = this.props;
        store.addCamera(camera, (err, result) => {
            if (err) {
                this.setState({
                    cameraMessage: {type: 'error', msg: _.get(err, 'response.data.error.message', 'An error')}
                })
            } else {
                this.setState({
                    cameraMessage: {type: 'success', msg: `${_.get(result,'name')} 가 정상적으로 등록되었습니다.`},
                    camera: {
                        name: "",
                        public: false,
                    }
                });
                let cameraResult = [];
                cameraResult.push(result);
                cameraData.map( (row, index) =>{
                    cameraResult.push(row);
                    return row;
                });
                this.props._setCameras(cameraResult);
            }
        });
    }

    render() {
        const {classes} = this.props;
        const {cameraMessage} = this.state;
        const {camera} = this.state;
        return (
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <CameraEnhance/>
                </Avatar>
                <Typography component={"h1"} variant={"h5"}>
                    카메라 등록
                </Typography>
                <form className={classes.cameraContainer} noValidate autoComplete="off"
                      onSubmit={this._onCameraSubmit}>
                    {cameraMessage.msg ? (
                        cameraMessage.type === "success" ? (
                            <Typography component={"h5"} variant={"h5"}>{cameraMessage.msg}</Typography>
                        ) : (
                            <Typography component={"h1"} variant={"h5"}>{cameraMessage.msg}</Typography>
                        )
                    ) : null}
                    <FormControl margin={"normal"} required fullWidth>
                        <InputLabel htmlFor="cameraPhone">핸드폰 번호</InputLabel>
                        <Input id="cameraPhone" name="cameraPhone" autoFocus
                               value={_.get(camera, "cameraPhone", "")}
                               onChange={this._onCameraTextFieldChange}/>
                    </FormControl>
                    <FormControl margin={"normal"} required fullWidth>
                        <InputLabel htmlFor="cameraName">카메라 이름</InputLabel>
                        <Input id="cameraName" name="cameraName" autoFocus
                               value={_.get(camera, "cameraName", "")}
                               onChange={this._onCameraTextFieldChange}/>
                    </FormControl>
                    <FormControl margin={"normal"} required fullWidth>
                        <InputLabel htmlFor="cameraIp">카메라 주소</InputLabel>
                        <Input id="cameraIp" name="cameraIp" autoFocus
                               value={_.get(camera, "cameraIp", "")}
                               onChange={this._onCameraTextFieldChange}/>
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        카메라 등록
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={this._onCameraCancel}
                    >
                        취소
                    </Button>
                </form>
            </Paper>
        );

    }
}

AddCamera.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddCamera);