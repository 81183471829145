import React, {Component} from "react";
import {history} from "../../../history";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import _ from "lodash";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginRight: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    }, paper: {
        marginTop: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: {
                type: "success",
                msg: null
            },
            user: {
                phone: "",
                password: ""
            },
            userID: "",
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._onTextFieldChange = this._onTextFieldChange.bind(this);
    }

    componentWillMount() {
        const {store} = this.props;
        this._loadCurrentUser(store);
    }

    _loadCurrentUser(store) {
        store.getCurrentUser((res) => {
            this._setCurrentUser(res);
        });
    }

    _setCurrentUser(res) {
        const userID = _.get(res, '_id', '');
        if (!userID) {
            history.push("/login");
        } else if (userID === "5bfb320b7bcbdd73d7351915") {
            history.push("/register");
        } else {
            history.push(`/watch/${userID}`);
        }
    }

    _onTextFieldChange(event) {
        let {user} = this.state;

        const field = event.target.name;
        const value = event.target.value;

        user[field] = value;

        this.setState({
            user: user
        });
    }

    _onSubmit(event) {
        const {user} = this.state;
        const {store} = this.props;
        const {updateUser} = this.props;

        event.preventDefault();

        store.login(user, (err, result) => {
            if (err) {
                this.setState({
                    message: {
                        type: "error",
                        msg: _.get(
                            err,
                            "response.data.error.message",
                            "로그인에 실패했습니다."
                        )
                    }
                });
            } else {
                this.setState({
                    message: {type: "success", msg: "로그인에 성공하였습니다."}
                });
                const token = store.loadTokenFromLocalStorage();
                const userID = _.get(token, "userId", "");
                updateUser(_.get(token, 'user', ''));
                if (userID === "5bfb320b7bcbdd73d7351915") {
                    history.push("/register");
                } else {
                    history.push(`/watch/${userID}`);
                }
            }
        });
    }

    render() {
        const {message} = this.state;
        const {classes} = this.props;

        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>

                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        로그인
                    </Typography>
                    <form className={classes.container} noValidate autoComplete="off" onSubmit={this._onSubmit}>
                        {message.msg ? (
                            message.type === "success" ? (
                                <Typography>{message.msg}</Typography>
                            ) : (
                                <Typography>{message.msg}</Typography>
                            )
                        ) : null}

                        <FormControl margin={"normal"} required fullWidth>
                            <InputLabel htmlFor="phone">핸드폰 번호</InputLabel>
                            <Input id="phone" name="phone" autoFocus
                                   value={this.state.phone}
                                   onChange={this._onTextFieldChange}/>
                        </FormControl>

                        <FormControl margin={"normal"} required fullWidth>
                            <InputLabel htmlFor="password">비밀번호</InputLabel>
                            <Input id="password" name="password" autoFocus
                                   value={this.state.password}
                                   type="password"
                                   onChange={this._onTextFieldChange}/>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            로그인
                        </Button>
                    </form>
                </Paper>
            </main>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);